<template>
  <div class="w-100">
    <div class="row">
      <div class="col">
        <h5 v-if="!hideTitle">
          {{ title }}
        </h5>
      </div>
    </div>

    <div class="row">
      <div class="col-11 pr-1">
        <v-select
          v-if="ingredientesDisponiveis.length > 0"
          :options="ingredientesDisponiveis"
          v-model="ingredienteSelecionado"
          label="ingrediente"
          @input="addIngrediente"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              Nenhum resultado para <em>{{ search }}</em
              >.
            </template>
            <em
              style="opacity: 0.5"
              v-else
              >Digite para procurar por um ingrediente.</em
            >
          </template>
        </v-select>
      </div>
      <div class="col-1 p-0">
        <b-btn
          size="sm"
          @click="$bvModal.show('modal-ingrediente')"
        >
          <i class="fas fa-plus" />
        </b-btn>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <b-table
          stacked="sm"
          :fields="campos"
          :items="ingredientesFiltrados"
          hover
          show-empty
          small
          class="shadow-sm p-1 mb-2 bg-white"
          outlined
          style="font-size: 11px"
          ref="tbIngredientes"
          id="tbIngredientes"
        >
          <template #empty>
            <h4 class="text-center">Nenhum dado para mostrar &#128543;</h4>
          </template>

          <template #cell(actions)="row">
            <b-btn
              size="sm"
              variant="transparent"
              @click="removerIngrediente(ingredientes.indexOf(row.item))"
            >
              <i class="fas fa-times text-left" />
            </b-btn>
          </template>
          <template #cell(preco_venda)="row">
            <money
              class="text-center"
              v-model="row.item.preco_venda"
              size="sm"
              v-bind="moneyMask"
            />
          </template>
          <template #cell(preco_custo)="row">
            <money
              class="text-center"
              v-model="row.item.preco_custo"
              size="sm"
              v-bind="moneyMask"
            />
          </template>
          <template #cell(ordem)="row">
            <div class="d-flex text-center">
              <div
                class="font-weight-bold mr-2"
                style="font-size: 18px"
              >
                {{ row.item.ordem }}
              </div>
              <div class="d-flex flex-column">
                <b-icon-chevron-up
                  @click="changeOrder('up', row.item)"
                  class="text-primary"
                  style="cursor: pointer"
                />
                <b-icon-chevron-down
                  @click="changeOrder('down', row.item)"
                  class="text-primary"
                  style="cursor: pointer"
                />
              </div>
            </div>
          </template>
        </b-table>
        <b-form-invalid-feedback
          :force-show="typeof state === 'boolean' && !state"
        >
          Insira ao menos um ingrediente!
        </b-form-invalid-feedback>
      </div>
    </div>

    <b-modal
      id="modal-ingrediente"
      size="lg"
      hide-footer
      hide-header
    >
      <Ingredientes
        :tipo="'INGREDIENTE'"
        :botao-adicionar="true"
        @adicionou="addIngrediente($event)"
        nome-customizado="Ingredientes"
        icone-customizado="fas fa-receipt"
        :breadcrumb="false"
        content-class="none"
        :empresas-status="false"
        :add-on-save="true"
      />
    </b-modal>
  </div>
</template>

<script>
import IngredientesLib from "@/libs/IngredientesLib";
import Ingredientes from "@/views/Configuracoes/Cadastros/Ingredientes.vue";
import ProdutoTipoPrecosLib from "../../libs/ProdutoTipoPrecosLib";

export default {
  components: {
    Ingredientes,
  },
  props: {
    title: { type: String, default: "Ingredientes" },
    hideTitle: { type: Boolean, default: false },
    ingredientesProp: { type: Array, default: () => [] },
    state: { type: Boolean, default: true },
    isPrecoUnico: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      campos: [
        { key: "ingrediente", label: "Ingrediente" },
        { key: "ordem", label: "Ordem", class: "text-center" },
        { key: "preco_venda", label: "Preço Venda", class: "text-center" },
        { key: "preco_custo", label: "Preço Custo", class: "text-center" },
        { key: "actions", label: "", class: "text-center" },
      ],
      ingredientesDisponiveis: [],
      ingredienteSelecionado: {},
      ingredientes: [],
      moneyMask: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",

        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    changeOrder(order, item) {
      let index = this.ingredientes.indexOf(item);
      if (order === "up") {
        if (index > 0) {
          let temp = this.ingredientes[index];
          this.ingredientes[index] = this.ingredientes[index - 1];
          this.ingredientes[index - 1] = temp;
          console.log("down", this.ingredientes);
          this.$set(this.ingredientes[index], "ordem", temp.ordem+1);
        }
      } else {
        if (index < this.ingredientes.length - 1) {
          let temp = this.ingredientes[index];
          this.ingredientes[index] = this.ingredientes[index + 1];
          this.ingredientes[index + 1] = temp;
          console.log("down", this.ingredientes);
          this.$set(this.ingredientes[index], "ordem", temp.ordem-1); 
        }
      }
      this.$emit("ingredientesChanged", {
        newValue: this.ingredientes,
        item: this.ingredientes[index],
      });
      //reorder all itens ordem field
      this.ingredientes.forEach((ingred, idx) => {
        this.$set(ingred, "ordem", idx + 1);
      });
      // this.$forceUpdate();
      // this.$refs.tbIngredientes.refresh();
    },
    async carregarIngredientesDisponiveis() {
      try {
        let tipos = await ProdutoTipoPrecosLib.get();
        this.ingredientesDisponiveis = (await IngredientesLib.get()).map(
          (i) => ({
            ...i,
            personalizarEstoque: tipos
              .filter(
                (x) =>
                  !this.isPrecoUnico || (this.isPrecoUnico && x.tipo == "UNICO")
              )
              .map((t) => ({
                cod_ingrediente: i.cod_ingrediente,
                ingrediente: i.ingrediente,
                tipo: t.tipo,
                cod_tipo_preco: t.cod_tipo,
                qtd_meia: 0.0,
                qtd_inteira: 0.0,
                _rowStatus: "added",
              })),
          })
        );
      } catch (error) {
        console.log("Erro ao buscar ingredientes", error);
        this.showToast("error", error.message);
      }
    },
    removerIngrediente(index) {
      if (
        this.ingredientes[index]._rowStatus &&
        this.ingredientes[index]._rowStatus == "added"
      ) {
        this.$emit("ingredientesChanged", {
          newValue: null,
          item: { ...this.ingredientes[index], _rowStatus: "deleted" },
        });
        this.ingredientes.splice(index, 1);
      } else {
        this.$set(this.ingredientes[index], "_rowStatus", "deleted");
        this.$emit("ingredientesChanged", {
          newValue: this.ingredientes,
          item: this.ingredientes[index],
        });
      }
    },
    addIngrediente(ingrediente) {
      // await this.carregarIngredientesDisponiveis()
      // console.log(ingrediente);
      if (
        ingrediente.cod_ingrediente &&
        ingrediente.cod_ingrediente.toString() != ""
      ) {
        // console.log(this.ingredientes)
        if (
          this.ingredientes.filter(
            (i) =>
              i._rowStatus !== "deleted" &&
              i.cod_ingrediente === ingrediente.cod_ingrediente
          ).length > 0
        ) {
          this.showToast(
            "error",
            `O ingrediente "${ingrediente.ingrediente.toLowerCase()}" já está incluido!`,
            2200
          );
          return;
        }
        let newIngrediente = { ...ingrediente, _rowStatus: "added" };
        // console.log("newIngredieten", newIngrediente  )
        this.ingredientes.push(newIngrediente);
        this.$emit("ingredientesChanged", {
          newValue: this.ingredientes,
          item: newIngrediente,
        });
      } else {
        this.showToast("error", "Selecione um ingrediente antes!");
      }
    },
  },
  async created() {
    this.carregarIngredientesDisponiveis();
    await this.$nextTick();
    this.$forceUpdate();
    if (this.ingredientesProp.length > 0) {
      // this.ingredientesProp.forEach((ingrediente) => {
      //   this.ingredientes.push(Object.assign({}, ingrediente));
      // });
      await this.$nextTick();
      this.ingredientes = this.ingredientesProp;
    }
  },
  computed: {
    ingredientesFiltrados() {
      this.$nextTick();
      this.$forceUpdate();
      return this.ingredientes
        .filter((ingred) => {
          return ingred._rowStatus && ingred._rowStatus === "deleted"
            ? false
            : true;
        })
        .sort((a, b) => a.ordem - b.ordem);
    },
  },
  watch: {
    ingredientesProp(newVal) {
      this.ingredientes = newVal;
    },
  },
};
</script>

<style></style>
